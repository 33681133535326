<script setup lang="ts">
import { WhatYouWaitingProps } from "./WhatYouWaiting.props"
import { sm, md, lg } from "~/utils/breakpoints"
import type { NotificationAlertProps } from "~/components/NotificationAlert.props"

const { applyCoupon } = useCart()
const { t } = useI18n()
const { addNotification } = useNotification()

const props = withDefaults(defineProps<WhatYouWaitingProps>(), {
  text: "",
  isPromoCodeUsed: false,
  promoCodeInfoText:
    "Clicca sul codice, lo troverai già applicato nel carrello",
  promoCodeInfoTextCopied: "Ben fatto!",
  promoTextCopied: "Codice inserito nel carrello"
})

const emit = defineEmits<{ (e: "onCopy", value: string): void }>()

const isPromoCodeCopied = ref(false)

const notificationSuccess: NotificationAlertProps = {
  id: Math.random(),
  type: "success",
  notificationAlertText: t("discounts.codeAppliedSuccessfully"),
  isWithCloseIcon: true
}

const notificationError: NotificationAlertProps = {
  id: Math.random(),
  type: "danger",
  notificationAlertText: t("discounts.codeAppliedError"),
  isWithCloseIcon: true
}

const handleApplyAndCopy = async () => {
  navigator.clipboard.writeText(props.promoCode)
  emit("onCopy", props.promoCode)
  isPromoCodeCopied.value = true

  try {
    await applyCoupon(props.promoCode)
    addNotification(notificationSuccess)
  } catch (error) {
    addNotification(notificationError)
  }
}

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})
</script>

<template>
  <div>
    <div class="relative flex flex-col items-center px-4 py-8 md:px-12 md:py-6">
      <UtilsWrapperImage
        v-if="bgImage"
        v-bind="bgImage"
        :key="bgImage.image.url"
        class="
          wrapper-image-full-container-centered
          absolute
          inset-0
          -z-10
          overflow-hidden
        "
      />
      <div
        class="
          flex flex-col
          items-center
          justify-center
          gap-8
          text-center text-white
        "
      >
        <div class="flex flex-col gap-4">
          <p v-if="title" class="hyppo-bold md:mammoth-bold lg:elephant-bold">
            {{ title }}
          </p>
          <UtilsMarkdown
            v-if="text"
            :content="text"
            class="pig md:dolphin lg:pig px-8 md:px-0"
          />
        </div>

        <div class="flex w-full flex-col items-center">
          <UtilsMarkdown
            v-if="!isPromoCodeCopied"
            class="promo-code__info-text"
            :content="promoCodeInfoText"
          />
          <UtilsMarkdown
            v-else
            class="promo-code__info-text"
            :content="promoCodeInfoTextCopied"
          />

          <div
            v-if="promoCode && !isPromoCodeCopied"
            class="promo-code__button cursor-pointer"
            @click="handleApplyAndCopy"
          >
            <UtilsIcon name="Copy.svg" class="h-6 w-6" /> {{ promoCode }}
          </div>
          <div v-else class="promo-code__button">
            <UtilsIcon name="CheckmarkCircle2.svg" class="h-6 w-6" />
            {{ promoTextCopied }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.promo-code__info-text {
  @apply mouse-bold
    md:beaver-bold
    mb-4
    flex
    flex-col
    gap-1
    uppercase
    md:mb-4
    md:flex-row;

  svg {
    @apply h-2 w-2;
  }
}

.promo-code__button {
  @apply beaver-bold
    flex
    w-full
    max-w-[394px]
    items-center
    justify-center gap-1
    bg-white py-[10px]
    uppercase
    text-black-main
    lg:max-w-[472px];
}

</style>
