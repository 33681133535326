<script setup lang="ts">
import type { ComponentPromoCodeProps } from "./ComponentPromoCode.props"
const props = defineProps<ComponentPromoCodeProps>()
</script>

<template>
  <div class="cms-component-promo-code">
    <BannerDiscountPromoWhatYouWaiting
      v-bind="props"
      :bg-image-large="props.bgImage"
    />
  </div>
</template>
